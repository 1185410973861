import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

export default class extends Controller {
  static targets = ["container", "pauseButton", "playButton"]
  static values = { lottieFile: String, loop: Boolean }

  initialize() {
    this.containerTarget.innerHTML = ""

    this.lottieAnimation = lottie.loadAnimation({
      container: this.containerTarget,
      renderer: "svg",
      path: `/animations/${this.lottieFileValue}`,
      loop: this.loopValue,
    })
  }

  pause() {
    this.lottieAnimation.pause()
    this.pauseButtonTarget.classList.add("hidden")
    this.playButtonTarget.classList.remove("hidden")
  }

  play() {
    this.lottieAnimation.play()
    this.pauseButtonTarget.classList.remove("hidden")
    this.playButtonTarget.classList.add("hidden")
  }
}
