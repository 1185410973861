import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static values = { closing: Boolean }

  initialize() {
    this.isOpen = this.element.open
    if (!this.isOpen) {
      // Force the initial height of the expansion panel to be zero to make the first open animate correctly
      this.contentTarget.style.setProperty("--expansion-panel--expanded-height", "0")
    }
  }

  toggle(e) {
    e.preventDefault()
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  close() {
    // Don't set this.element.open = false yet, wait for the expansion panel to finish closing.
    this.isOpen = false
    this.closingValue = true
    this.dispatch("close")

    // If animations are disabled then call finalizeClose now
    if (window.getComputedStyle(this.contentTarget).transitionProperty === "none") {
      this.finalizeClose()
    }
  }

  open() {
    this.element.open = true
    this.isOpen = true
    this.closingValue = false
    this.dispatch("open")
  }

  finalizeClose() {
    this.element.open = false
  }
}
