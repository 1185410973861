import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {
  static targets = [
    "lottie",
    "name",
    "appearTop",
    "appearOpacity",
    "statsDesktop",
    "statsMobile",
    "statSection",
    "debtStat",
    "issueStat",
    "minuteStat",
    "secondStat",
    "trifectaContainer",
    "trifecta",
    "people",
    "bigText",
    "peopleDesktop",
    "peopleMobile",
    "enterprise",
    "biggifyContainer",
    "businessDesktop",
    "businessMobile",
    "colorButton",
  ]

  initialize() {
    //Lottie init
    this.lottieTargets.forEach((element) => {
      element.innerHTML = ""
    })

    //Animation for header
    anime({
      targets: this.appearTopTargets,
      translateY: [100, 0],
      opacity: [0, 1],
      duration: 500,
      easing: "easeInQuad",
    })
    anime({
      targets: this.appearOpacityTargets,
      opacity: [0, 1],
      duration: 1000,
      easing: "easeInQuad",
      delay: 200,
    })

    setTimeout(() => {
      lottie.loadAnimation({
        container: this.nameTarget,
        renderer: "svg",
        path: "/animations/social-impact/name.json",
        loop: false,
        autoplay: true,
      })
    }, 1000)

    //Other type animations init
    this.initStats()
    this.biggify = this.initBiggify()
    this.colorify = this.initColorify()

    //Lottie inits
    this.stats = this.initPausedLottie(this.statsDesktopTarget, "/animations/social-impact/stats-desktop.json")
    this.statsMobile = this.initPausedLottie(this.statsMobileTarget, "/animations/social-impact/stats-mobile.json")

    this.people = this.initPausedLottie(this.peopleDesktopTarget, "/animations/social-impact/people-desktop.json")
    this.peopleMobile = this.initPausedLottie(this.peopleMobileTarget, "/animations/social-impact/people-mobile.json")

    this.business = this.initPausedLottie(this.businessDesktopTarget, "/animations/social-impact/business-desktop.json")
    this.businessMobile = this.initPausedLottie(
      this.businessMobileTarget,
      "/animations/social-impact/business-mobile.json",
    )

    this.trifecta = this.initPausedLottie(this.trifectaTarget, "/animations/social-impact/trifecta-desktop.json")
  }

  //intialise method for init lottie animations
  initPausedLottie(target, pathName) {
    return lottie.loadAnimation({
      container: target,
      renderer: "svg",
      path: pathName,
      loop: false,
      autoplay: false,
    })
  }

  //intialise method for init lottie animations
  initPlayedLottie(target, pathName) {
    return lottie.loadAnimation({
      container: target,
      renderer: "svg",
      path: pathName,
      loop: false,
      autoplay: true,
    })
  }

  //intialise method for creating growing text
  initBiggify() {
    return anime({
      targets: this.biggifyContainerTarget.getElementsByTagName("span"),
      fontSize: function (el, i, l) {
        if (l - i == 2 || l - i == 3) {
          return ["32px", "200px"]
        } else if (l - i == 1) {
          return ["20px", "50px"]
        } else {
          if (window.innerWidth >= 1024) {
            return ["32px", "60px"]
          } else {
            return ["16px", "35px"]
          }
        }
      },
      color: function (el, i, l) {
        if (l - i == 1) {
          return "#ED4040"
        } else {
          return "#28ED91"
        }
      },
      easing: "easeOutCubic",
      autoplay: false,
      delay: function (el, i, l) {
        if (window.innerWidth >= 1024) {
          if (l - i <= 3) {
            return i * 150 - 1000
          } else {
            return i * 150 - 100
          }
        } else {
          if (l - i <= 3) {
            return i * 150 + 1000
          } else {
            return i * 150 + 0
          }
        }
      },
    })
  }

  // initialise method for colouring the final download button
  initColorify() {
    return anime({
      targets: this.colorButtonTarget,
      backgroundColor: "#28ED91",
      boxShadow: [
        "0 20px 25px -5px rgba(40,237,145, 0.0), 0 10px 10px -5px rgba(40,237,145, 0.0)",
        "0 20px 25px -5px rgba(40,237,145, 0.4), 0 10px 10px -5px rgba(40,237,145, 0.2)",
      ],
      easing: "easeOutCubic",
      autoplay: false,
    })
  }

  // initialise method for numbers scrolling from 0 -> final number
  initStats() {
    let data = { debt: 0, issue: 0, minute: 0, second: 0 }
    let targets = {
      debt: this.debtStatTarget,
      issue: this.issueStatTarget,
      second: this.secondStatTarget,
      minute: this.minuteStatTarget,
    }
    this.debt = anime({
      targets: data,
      debt: [0, 32326],
      round: 1,
      easing: "easeOutCubic",
      autoplay: false,
      update() {
        targets.debt.innerText = data.debt.toLocaleString()
      },
    })
    this.issue = anime({
      targets: data,
      issue: [0.0, 5.6],
      round: 10,
      easing: "easeOutCubic",
      autoplay: false,
      update() {
        targets.issue.innerText = data.issue.toLocaleString()
      },
    })
    this.minute = anime({
      targets: data,
      minute: [0, 4],
      round: 1,
      easing: "easeOutCubic",
      autoplay: false,
      duration: 500,
      update() {
        targets.minute.innerText = data.minute.toLocaleString()
      },
    })
    this.second = anime({
      targets: data,
      second: [0, 38],
      round: 1,
      easing: "easeOutCubic",
      autoplay: false,
      update() {
        targets.second.innerText = data.second.toLocaleString()
      },
    })
  }

  //Method to find out if element is in view
  inView(elem, offset) {
    let windowHeight = window.innerHeight
    let positionFromTop = elem.getBoundingClientRect().top
    let elemHeight = elem.offsetHeight

    if (positionFromTop <= windowHeight - offset && elemHeight + positionFromTop >= offset) {
      return true
    } else {
      return false
    }
  }

  //Method for animating part 1 of the page
  animateFirstLine(animation, offset, animationMobile, offsetMobile) {
    if (window.innerWidth >= 1024) {
      var animationLogic = animation
      var offsetLogic = offset
    } else {
      var animationLogic = animationMobile
      var offsetLogic = offsetMobile
    }

    let imageHeight = animationLogic.wrapper.offsetHeight + offsetLogic
    let maxFrames = animationLogic.totalFrames
    let scrollPercent = window.pageYOffset / (imageHeight - window.innerHeight)
    let frame = maxFrames * scrollPercent
    if (scrollPercent < 1) {
      animationLogic.goToAndStop(frame, true)
    } else {
      animationLogic.goToAndStop(maxFrames - 1, true)
    }
  }

  //Method for animating part 2 of the page
  animateOtherLine(animation, offset, speed, animationMobile, offsetMobile, speedMobile) {
    if (window.innerWidth >= 1024) {
      var animationLogic = animation
      var offsetLogic = offset
      var speedLogic = speed
    } else {
      var animationLogic = animationMobile
      var offsetLogic = offsetMobile
      var speedLogic = speedMobile
    }

    let imageHeight = animationLogic.wrapper.offsetHeight + offsetLogic
    let maxFrames = animationLogic.totalFrames
    let scrollPercent =
      ((window.innerHeight - animationLogic.wrapper.getBoundingClientRect().top) * speedLogic) /
      (imageHeight + window.innerHeight)
    let frame = maxFrames * scrollPercent
    if (scrollPercent < 1) {
      animationLogic.goToAndStop(frame, true)
    } else {
      animationLogic.goToAndStop(maxFrames - 1, true)
    }
  }

  //Method for animating circular diagram
  animateTrifecta(target) {
    let containerHeight = target.offsetHeight
    let containerFromTop = target.getBoundingClientRect().top
    if (window.innerWidth >= 1024) {
      var offset = 600
    } else {
      var offset = 1000
    }
    let scrollPercent = (-containerFromTop + offset) / (containerHeight - window.innerHeight) - 0.2

    if (this.inView(target, 0)) {
      let maxFramesTri = this.trifecta.totalFrames
      let frameTri = maxFramesTri * scrollPercent
      if (scrollPercent < 1) {
        this.trifecta.goToAndStop(frameTri, true)
      } else {
        this.trifecta.goToAndStop(maxFramesTri - 1, true)
      }

      let change = 0.6
      let duration = 0.15

      if (scrollPercent <= 0.3) {
        this.peopleTarget.style.opacity = scrollPercent / 0.3
      }

      if (scrollPercent >= change - duration && scrollPercent <= change) {
        this.peopleTarget.style.opacity = 1 - (scrollPercent - change + duration) / duration
      }

      if (scrollPercent >= change) {
        this.peopleTarget.style.opacity = 0
      }

      if (scrollPercent <= change) {
        this.enterpriseTarget.style.opacity = 0
      }
      if (scrollPercent >= change && scrollPercent <= change + duration) {
        this.enterpriseTarget.style.opacity = (scrollPercent - change) / duration
      }
      if (scrollPercent >= 1) {
        this.enterpriseTarget.style.opacity = 1
      }
    }
  }

  // Method for animating the growing text
  animateBiggify(target, animation, delayDesktop, delayMobile) {
    if (window.innerWidth >= 1024) {
      var delayLogic = delayDesktop
    } else {
      var delayLogic = delayMobile
    }
    var windowHeight = window.innerHeight
    target.forEach((elem) => {
      if (this.inView(elem, delayLogic)) {
        animation.seek(
          animation.duration * ((windowHeight - (elem.getBoundingClientRect().top + delayLogic)) / windowHeight),
        )
      }
    })
  }

  // Method for animating scrolling numbers
  animateStats() {
    let windowHeight = window.innerHeight

    if (window.innerWidth >= 1024) {
      var debtDelay = 0
      var issueDelay = 0
      var minuteDelay = 350
      var secondDelay = 500
    } else {
      var debtDelay = 0
      var issueDelay = 500
      var minuteDelay = 700
      var secondDelay = 1000
    }

    this.statSectionTargets.forEach((elem) => {
      let positionFromTop = elem.getBoundingClientRect().top
      if (this.inView(elem, 0)) {
        this.debt.seek(-debtDelay + this.debt.duration * (((windowHeight - positionFromTop) * 1.5) / windowHeight))
        this.issue.seek(-issueDelay + this.issue.duration * (((windowHeight - positionFromTop) * 1.5) / windowHeight))
        this.minute.seek(
          -minuteDelay + this.minute.duration * (((windowHeight - positionFromTop) * 1.5) / windowHeight),
        )
        this.second.seek(
          -secondDelay + this.second.duration * (((windowHeight - positionFromTop) * 1.5) / windowHeight),
        )
      }
    })
  }

  // Method for animating the growing text
  animateBigText() {
    if (window.innerWidth >= 1024) {
      var offset = 350
    } else {
      var offset = 100
    }

    this.bigTextTargets.forEach((elem) => {
      if (this.inView(elem, offset)) {
        const listItems = elem.children
        const listArray = Array.from(listItems)
        listArray.forEach((item) => {
          item.style.opacity = 1
          item.style.transform = "translateY(0px)"
        })
      } else {
        const listItems = elem.children
        const listArray = Array.from(listItems)
        listArray.forEach((item) => {
          item.style.opacity = 0
          item.style.transform = "translateY(20px)"
        })
      }
    })
  }

  // Method for animating the change in colour of button
  animateColorify(target, animation, offset) {
    let windowHeight = window.innerHeight
    if (this.inView(target, 0)) {
      animation.seek(
        animation.duration * (((windowHeight - target.getBoundingClientRect().top) / windowHeight) * 2) - offset,
      )
    }
  }

  // On scroll do this:
  scroll() {
    //Section 1: Intro
    this.animateFirstLine(this.stats, 250, this.statsMobile, 0)
    this.animateStats()

    this.animateBigText()

    //Section 2: People
    this.animateTrifecta(this.trifectaContainerTarget)
    this.animateOtherLine(this.people, -75, 1, this.peopleMobile, -75, 1)

    //Section 3: Businesses
    this.animateOtherLine(this.business, 0, 1.23, this.businessMobile, 0, 1.07)
    this.animateBiggify(this.biggifyContainerTargets, this.biggify, 0, 150)
    this.animateColorify(this.colorButtonTarget, this.colorify, 450)
  }
}
