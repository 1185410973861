import { Controller } from "@hotwired/stimulus"

/**
 * Controller for a form input.
 *
 * Used to display client-side validation messages. Supports targets for every property of
 * [ValidityState]{@link https://developer.mozilla.org/en-US/docs/Web/API/ValidityState} except <code>valid</code>,
 * which instead has an <code>invalid</code> target for its negation.
 *
 * @example
 * <div data-controller="form--input" data-form--input-hide-validation-class="hidden">
 *   <input type="text"
 *       data-form--input-target="input"
 *       data-action="blur->form--input#showValidations:once invalid->form--input#showValidations:once form--input#validate"
 *       name="username" required>
 *   <div data-form--input-target="valueMissing" class="hidden">Username is required</div>
 * </div>
 */
export default class extends Controller {
  static targets = [
    "input",
    "badInput",
    "customError",
    "patternMismatch",
    "rangeOverflow",
    "rangeUnderflow",
    "stepMismatch",
    "tooLong",
    "tooShort",
    "typeMismatch",
    "valueMissing",
    "invalid",
  ]
  static classes = ["showValidation", "hideValidation"]

  initialize() {
    this.validationsVisible = false
    this.customValidators = {}
  }

  /**
   * Registers custom validation for this input controller.
   *
   * @example
   * import InputController from "./input_controller"
   *
   * export default class extends InputController {
   *   initialize() {
   *     super.initialize()
   *     registerCustomValidation("myvalidation", this.isValid)
   *   }
   * }
   *
   * <div data-form--input-target="customError" data-validation="myvalidation" class="hidden">Input is not valid</div>
   *
   * @param name An identifying name for this validator
   * @param validator A function that takes the value of the input and returns whether the value is valid.
   * @param validationMessage The message displayed by the browser's native validation error display.
   */
  registerCustomValidation(name, validator, validationMessage) {
    this.customValidators[name] = { validate: validator, message: validationMessage }
  }

  inputTargetConnected() {
    this.validate()
  }

  badInputTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.badInput,
    )
  }

  customErrorTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible &&
        this.hasInputTarget &&
        !this.customValidators[target.dataset.validation].validate(this.inputTarget.value),
    )
  }

  patternMismatchTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.patternMismatch,
    )
  }

  rangeOverflowTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.rangeOverflow,
    )
  }

  rangeUnderflowTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.rangeUnderflow,
    )
  }

  stepMismatchTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.stepMismatch,
    )
  }

  tooLongTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.tooLong,
    )
  }

  tooShortTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.tooShort,
    )
  }

  typeMismatchTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.typeMismatch,
    )
  }

  invalidTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && !this.inputTarget.validity.valid,
    )
  }

  valueMissingTargetConnected(target) {
    this.toggleValidationClasses(
      target,
      this.validationsVisible && this.hasInputTarget && this.inputTarget.validity.valueMissing,
    )
  }

  showValidations() {
    this.validationsVisible = true
    this.validate()
  }

  validate() {
    let customValidationErrors = []
    let customValidationMessages = []
    if (this.inputTarget.value) {
      for (const [name, validator] of Object.entries(this.customValidators)) {
        if (!validator.validate(this.inputTarget.value)) {
          customValidationErrors.push(name)
          customValidationMessages.push(validator.message)
        }
      }
    }
    this.inputTarget.setCustomValidity(customValidationMessages.join(" "))

    if (this.validationsVisible) {
      this.badInputTargets.forEach((target) => this.toggleValidationClasses(target, this.inputTarget.validity.badInput))
      this.customErrorTargets.forEach((target) => {
        this.toggleValidationClasses(target, customValidationErrors.includes(target.dataset.validation))
      })
      this.patternMismatchTargets.forEach((target) =>
        this.toggleValidationClasses(target, this.inputTarget.validity.patternMismatch),
      )
      this.rangeOverflowTargets.forEach((target) =>
        this.toggleValidationClasses(target, this.inputTarget.validity.rangeOverflow),
      )
      this.rangeUnderflowTargets.forEach((target) =>
        this.toggleValidationClasses(target, this.inputTarget.validity.rangeUnderflow),
      )
      this.stepMismatchTargets.forEach((target) =>
        this.toggleValidationClasses(target, this.inputTarget.validity.stepMismatch),
      )
      this.tooLongTargets.forEach((target) => this.toggleValidationClasses(target, this.inputTarget.validity.tooLong))
      this.tooShortTargets.forEach((target) => this.toggleValidationClasses(target, this.inputTarget.validity.tooShort))
      this.typeMismatchTargets.forEach((target) =>
        this.toggleValidationClasses(target, this.inputTarget.validity.typeMismatch),
      )
      this.invalidTargets.forEach((target) => this.toggleValidationClasses(target, !this.inputTarget.validity.valid))
      this.valueMissingTargets.forEach((target) =>
        this.toggleValidationClasses(target, this.inputTarget.validity.valueMissing),
      )
    } else {
      this.badInputTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.customErrorTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.patternMismatchTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.rangeOverflowTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.rangeUnderflowTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.stepMismatchTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.tooLongTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.tooShortTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.typeMismatchTargets.forEach((target) => this.toggleValidationClasses(target, false))
      this.valueMissingTargets.forEach((target) => this.toggleValidationClasses(target, false))
    }
  }

  toggleValidationClasses(target, show) {
    if (show) {
      target.classList.remove(...this.hideValidationClasses)
      target.classList.add(...this.showValidationClasses)
    } else {
      target.classList.remove(...this.showValidationClasses)
      target.classList.add(...this.hideValidationClasses)
    }
  }
}
