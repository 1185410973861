import { Controller } from "@hotwired/stimulus"

export default class TabListController extends Controller {
  static targets = ["tab"]
  declare readonly tabTargets: HTMLElement[]

  tabTargetConnected(tab: HTMLElement) {
    if (tab.getAttribute("aria-selected") == "true") {
      this.showPanel(tab)
    }
  }

  showPanel(activeTab: HTMLElement) {
    this.tabTargets.forEach((tab) => {
      const panelId = tab.getAttribute("aria-controls")
      const panel = document.getElementById(panelId)

      if (tab === activeTab) {
        this.activateTab(tab)
        if (panel) {
          panel.classList.remove("hidden")
        }
      } else {
        this.deactivateTab(tab)
        if (panel) {
          panel.classList.add("hidden")
        }
      }
    })
  }

  activateTab(tab: HTMLElement) {
    tab.classList.add("tab--active")
    tab.setAttribute("aria-selected", "true")
    tab.removeAttribute("tabindex")
  }

  deactivateTab(tab: HTMLElement) {
    tab.classList.remove("tab--active")
    tab.removeAttribute("aria-selected")
    tab.setAttribute("tabindex", "-1")
  }

  selectTab(event: Event) {
    const target = event.target as HTMLElement
    if (this.tabTargets.indexOf(target) !== -1) {
      this.showPanel(target)
    }
  }

  navigateTabs(event: KeyboardEvent) {
    const currentIndex = this.tabTargets.findIndex((tab) => tab.getAttribute("aria-selected") === "true")
    const orientation = this.element.getAttribute("aria-orientation") || "horizontal"
    let newIndex = currentIndex

    switch (event.key) {
      case "ArrowLeft":
        if (orientation === "horizontal") {
          newIndex = this.findPreviousIndex(currentIndex)
        }
        break
      case "ArrowRight":
        if (orientation === "horizontal") {
          newIndex = this.findNextIndex(currentIndex)
        }
        break
      case "ArrowUp":
        if (orientation === "vertical") {
          newIndex = this.findPreviousIndex(currentIndex)
        }
        break
      case "ArrowDown":
        if (orientation === "vertical") {
          newIndex = this.findNextIndex(currentIndex)
        }
        break
    }

    if (newIndex !== currentIndex) {
      this.deactivateTab(this.tabTargets[currentIndex])
      this.activateTab(this.tabTargets[newIndex])
      this.tabTargets[newIndex].focus()
      event.preventDefault()
    }
  }

  findPreviousIndex(currentIndex: number): number {
    return currentIndex === 0 ? this.tabTargets.length - 1 : currentIndex - 1
  }

  findNextIndex(currentIndex: number): number {
    return currentIndex === this.tabTargets.length - 1 ? 0 : currentIndex + 1
  }
}
