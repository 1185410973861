import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

export default class extends Controller {
  static targets = ["container"]

  initialize() {
    this.containerTargets.forEach((element) => {
      element.innerHTML = ""
    })

    lottie.loadAnimation({
      container: document.getElementById("happy"), // Required
      renderer: "svg", // Required
      path: "/animations/happy.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    var front = lottie.loadAnimation({
      container: document.getElementById("lighthouse-front"), // Required
      renderer: "svg", // Required
      path: "/animations/lighthouse-front.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })
    front.setSpeed(0.75)

    var back = lottie.loadAnimation({
      container: document.getElementById("lighthouse-back"), // Required
      renderer: "svg", // Required
      path: "/animations/lighthouse-back.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })
    back.setSpeed(0.75)

    var find = lottie.loadAnimation({
      container: document.getElementById("find"), // Required
      renderer: "svg", // Required
      path: "/animations/find.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })
    find.setSpeed(0.5)
  }
}
