import Honeybadger from "@honeybadger-io/js"

const script = document.getElementById("honeybadger-js") as HTMLScriptElement

const config = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.RAILS_ENV,
  revision: process.env.OPHELOS_SHA,
}

// TODO: Re-enable Honeybadger JavaScript alerts
// Honeybadger.configure(config)

export default Honeybadger
