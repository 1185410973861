import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "button"]

  onScroll() {
    if (document.documentElement.scrollTop > 40) {
      this.containerTarget.classList.add("scrolled")
      if (this.hasButtonTarget && this.buttonTarget.classList.contains("dark")) {
        this.buttonTarget.classList.add("btn-ghost")
        this.buttonTarget.classList.remove("btn-ghost-dark")
      }
    } else {
      this.containerTarget.classList.remove("scrolled")
      if (this.hasButtonTarget && this.buttonTarget.classList.contains("dark")) {
        this.buttonTarget.classList.add("btn-ghost-dark")
        this.buttonTarget.classList.remove("btn-ghost")
      }
    }
  }
}
