import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  closeSiblings({ currentTarget }) {
    for (const detail of this.element.children) {
      if (detail.firstElementChild !== currentTarget) {
        this.dispatch("sibling-opened", { target: detail, bubbles: false })
      }
    }
  }

  moveFocus({ code, currentTarget }) {
    const currentDetails = currentTarget.parentElement
    switch (code) {
      case "ArrowDown":
        currentDetails.nextElementSibling?.firstElementChild?.focus()
        break
      case "ArrowUp":
        currentDetails.previousElementSibling?.firstElementChild?.focus()
        break
      case "Home":
        this.element.firstElementChild.firstElementChild.focus()
        break
      case "End":
        this.element.lastElementChild.firstElementChild.focus()
        break
    }
  }
}
