import { Controller } from "@hotwired/stimulus"
import ResizeObserver from "resize-observer-polyfill"

export default class extends Controller {
  static targets = ["input", "box", "caret"]
  static classes = ["focus"]

  static resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      // contentBoxSize is not yet supported by all browsers, fall back on contentRect if it is not an Array
      if (Array.isArray(entry.contentBoxSize)) {
        entry.target.style.setProperty("--code-input-width", `${entry.contentBoxSize[0].inlineSize}px`)
      } else {
        entry.target.style.setProperty("--code-input-width", `${entry.contentRect.width}px`)
      }
    }
  })

  connect() {
    this.constructor.resizeObserver.observe(this.element, { box: "content-box" })
  }

  disconnect() {
    this.constructor.resizeObserver.unobserve(this.element)
  }

  fixScroll(event) {
    // When the caret moves outside the element's bounds the input gets scrolled, this undoes that behaviour
    event.target.scrollTo(0, 0)
  }

  selectCharacter({ params: { character } }) {
    if (document.activeElement !== this.inputTarget) {
      this.inputTarget.focus()
    }
    if (this.inputTarget.value.length > character) {
      this.inputTarget.setSelectionRange(character, character + 1)
    } else {
      this.inputTarget.setSelectionRange(this.inputTarget.value.length, this.inputTarget.value.length)
    }
    this.updateBoxes()
  }

  updateBoxes(event) {
    // Reset styles
    this.boxTargets.forEach((boxTarget) => boxTarget.classList.remove(...this.focusClasses))
    this.caretTarget.classList.add("hidden")
    if (document.activeElement !== this.inputTarget) return

    // The input should be treated as though it has insert-mode, i.e. if the caret is not at the end then at least one
    // character should be selected.

    let selectionStart = this.inputTarget.selectionStart
    let selectionEnd = this.inputTarget.selectionEnd

    if (selectionStart === selectionEnd) {
      if (selectionStart === this.inputTarget.value.length && selectionStart < this.inputTarget.maxLength) {
        // Here the caret is at the end of the value, and the value is not yet at the required length, so the caret
        // should be displayed in the first empty box.
        this.boxTargets[selectionStart].classList.add(...this.focusClasses)
        this.caretTarget.classList.remove("hidden")
        this.caretTarget.style.gridColumnStart = selectionStart + 1
      } else {
        // Here we need to change the selection so that a character is selected.
        if (selectionStart === this.inputTarget.maxLength) {
          // We're at the end of the input, so highlight the last character.
          this.inputTarget.setSelectionRange(selectionStart - 1, selectionStart)
          this.boxTargets[selectionStart - 1].classList.add(...this.focusClasses)
        } else {
          // Otherwise highlight the character just after where the caret currently is.
          this.inputTarget.setSelectionRange(selectionStart, selectionStart + 1)
          this.boxTargets[selectionStart].classList.add(...this.focusClasses)
        }
      }
    } else {
      this.boxTargets
        .slice(selectionStart, selectionEnd)
        .forEach((boxTarget) => boxTarget.classList.add(...this.focusClasses))
      if (event?.type === "keydown" && selectionEnd - selectionStart === 1) {
        // If there is exactly one character currently selected and the user is pressing the left/right key we might
        // need to modify the selection range for the expected behaviour.
        if (event.shiftKey) {
          // Holding shift means the user is planning to expand the selection to more than one character. In order
          // to make sure the selection is expanded in the correct direction we need to make sure the selection's
          // direction is correct.
          if (event.key === "ArrowLeft") {
            this.inputTarget.setSelectionRange(selectionStart, selectionEnd, "backward")
          } else if (event.key === "ArrowRight") {
            this.inputTarget.setSelectionRange(selectionStart, selectionEnd, "forward")
          }
        } else if (event.key === "ArrowLeft") {
          // Left alone, this event would remove the selection and then the code above would reselect the same
          // character, making the left arrow key useless. By clearing the selection so the caret is just at the start
          // of the current character the default behaviour will move it to the start of the previous character and the
          // code above will make that character selected.
          this.inputTarget.setSelectionRange(selectionStart, selectionStart)
        }
      }
    }
  }
}
