import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import Litepicker from "litepicker"
import Inputmask from "inputmask" // TODO (datepicker): delete when accessible datepicker is available

export default class extends Controller {
  static targets = [
    "step",
    "backButton",
    "nextButton",
    "screen",
    "form",
    "unit",
    "content",
    "buttons",
    "slug",
    "email",
    "submit",
    "tags",
    "income",
    "expenses",
    "mask",
  ]

  initialize() {
    localStorage.setItem("budgetSubmitted", "false") // This is bad.
    this.showCurrentStep()
    this.setTags()
    // list of steps for analytics
    this.steps = [
      "About you - personal details, contact",
      "About you - personal details, address",
      "About you - personal details, vulnerability",
      "About you - housing details",
      "About you - family details, marital",
      "About you - family details, rely",
      "About you - employment details",
      "Financials - selection",
      "Financials - CK",
      "Financials - CK success",
      "Income - earnings",
      "Income - benefits",
      "Income - pensions",
      "Income - other inflows",
      "Income - summary",
      "Fixed expenses - home",
      "Fixed expenses - utilities",
      "Fixed expenses - pensions & insurance",
      "Fixed expenses - care & health",
      "Fixed expenses - transport & travel",
      "Fixed expenses - school",
      "Fixed expenses - professional",
      "Variable expenses - communication & leisure",
      "Variable expenses - food & housekeeping",
      "Variable expenses - personal costs",
      "Expenses - summary",
      "Priority debts - step1",
      "Priority debts - step2",
      "Priority debts - step3",
      "Non-priority debts",
    ]
    // track step
    analytics.track("Begin I/E", {
      currentStep: this.steps[this.index],
    })

    if (document.getElementById("budget_budget_personal_detail_attributes_dob")) {
      new Litepicker({
        element: document.getElementById("budget_budget_personal_detail_attributes_dob"),
        format: "DD/MM/YYYY",
        setup: (picker) => {
          picker.on("selected", (date) => {
            document.getElementById("budget_budget_personal_detail_attributes_dob").innerHTML =
              date.format("DD/MM/YYYY")
            this.showCurrentStep()
          })
        },
      })
    }

    // TODO (datepicker): delete when accessible datepicker is available
    Inputmask("datetime", {
      inputFormat: "dd/mm/yyyy",
      outFormat: "dd/mm/yyyy",
      clearMaskOnLostFocus: false,
    }).mask(this.maskTarget)
  }

  next() {
    let controller = this
    // check for errors in current step before proceeding
    if (this.currentStepIsValid()) {
      if (this.index < this.lastIndex) {
        this.index++
        // track step
        analytics.track("I/E Step Forward", {
          currentStep: this.steps[this.index],
        })
        if (this.index < 8 || this.index > 10) {
          this.submitTarget.value = false
          const data = new FormData(this.formTarget)
          Rails.ajax({
            type: "post",
            url: `/budgets/assessment/${controller.slugTarget.value}`,
            data,
            success: function () {
              controller.getData()
            },
          })
        }
      } else {
        const budgetSubmitted = localStorage.getItem("budgetSubmitted") // This is bad.

        // This is all bad. But it's better than sending 100s of duplicate emails to Front 😅
        if (budgetSubmitted === "false") {
          this.submitTarget.value = true
          this.formTarget.requestSubmit()
          localStorage.setItem("budgetSubmitted", "true")
        }
      }
    }
  }

  getData() {
    let controller = this
    if (this.index === 14 || this.index === 25) {
      Rails.ajax({
        type: "get",
        url: `/budgets/assessment/${controller.slugTarget.value}/income_expense`,
        success: function (data) {
          controller.incomeTarget.innerHTML = data.income
          controller.expensesTarget.innerHTML = data.expenses
        },
        error: function (error) {
          console.log(error)
        },
      })
    }
  }

  setTags() {
    if (this.index === 2) {
      const tags = []
      const more = []
      const tagsElement = document.querySelectorAll("[tags]")
      tagsElement.forEach((tag) => {
        tags.push(tag.value)
      })
      this.tagsTarget.value.split(",").forEach((tag) => {
        if (!tags.includes(tag)) {
          more.push(tag)
        }
      })

      document.getElementsByClassName("more_tag")[0].value = more
    }

    if (this.index === 3) {
      const tagsElement = document.querySelectorAll("[tags]")
      const moreElement = document.getElementsByClassName("more_tag")[0].value
      let tags = moreElement.length > 0 ? [moreElement] : []
      tagsElement.forEach((tag) => {
        if (tag.checked) {
          tags.push(tag.value)
        }
      })

      this.tagsTarget.value = tags
    }
  }

  manualForm() {
    this.index = 10
    // track step
    analytics.track("I/E Fill Manually", {
      currentStep: this.steps[10],
    })
  }

  previous() {
    this.index--
    // track step
    analytics.track("I/E Step Backward", {
      currentStep: this.steps[this.index],
    })
  }

  currentStepIsValid() {
    let currentStep = document.getElementsByClassName("step--show")[0]
    let inputs = currentStep.querySelectorAll("input")
    let valid = true
    for (let i = 0; i < inputs.length; i++) {
      valid &&= inputs[i].reportValidity()
    }
    return valid
  }

  showCurrentStep() {
    this.setButtons()
    this.stepTargets.forEach((element, index) => {
      element.classList.toggle("step--show", index === this.index)
    })

    this.unitTargets[0].classList.toggle("lg:block", this.index < 0)
    this.unitTargets[1].classList.toggle("lg:block", this.index >= 0 && this.index <= 6)
    this.unitTargets[2].classList.toggle("lg:block", this.index >= 10 && this.index <= 14)
    this.unitTargets[3].classList.toggle("lg:block", this.index >= 15 && this.index <= 25)
    this.unitTargets[4].classList.toggle("lg:block", this.index >= 26 && this.index <= 29)

    this.screenTargets.forEach((element) => {
      element.classList.toggle("dark", this.index > 6 && this.index < 10)
    })
    this.buttonsTargets.forEach((element) => {
      element.classList.toggle("hidden", this.index > 6 && this.index < 10)
    })
    this.contentTargets.forEach((element) => {
      element.classList.toggle("lg:ml-24", !(this.index > 6 && this.index < 10))
    })
  }

  setButtons() {
    this.backButtonTargets.forEach((element) => {
      element.classList.toggle("hidden", this.index === 0 || this.index === 9 || this.index === 10)
    })

    this.nextButtonTargets.forEach((element) => {
      element.classList.toggle("hidden", this.index === this.lastIndex + 1)
    })
  }

  get index() {
    return this.data.has("index") ? parseInt(this.data.get("index")) : 0
  }

  set index(value) {
    this.data.set("index", value)
    this.showCurrentStep()
    this.setTags()
  }

  get lastIndex() {
    return this.stepTargets.length - 1
  }
}
