import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["controller", "controlled"]

  static nextId = 1

  initialize() {
    this.controlledTarget.id ||= `expandable-${this.constructor.nextId++}`
    this.controllerTarget.setAttribute("aria-controls", this.controlledTarget.id)
    this.expanded = this.controllerTarget.getAttribute("aria-expanded") === "true"
    this.controllerTarget.setAttribute("aria-expanded", this.expanded)
  }

  toggle() {
    this.#setExpanded(!this.expanded)
  }

  expand() {
    this.#setExpanded(true)
  }

  collapse() {
    this.#setExpanded(false)
  }

  #setExpanded(expanded) {
    if (this.expanded === expanded) return
    this.expanded = expanded
    this.controllerTarget.setAttribute("aria-expanded", expanded)
    this.dispatch(expanded ? "expand" : "collapse", { target: this.controlledTarget, bubbles: false })
  }
}
