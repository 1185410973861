import InputController from "./input_controller"
import { isValidPhoneNumber } from "libphonenumber-js"

export default class extends InputController {
  initialize() {
    super.initialize()
    this.registerCustomValidation(
      "phonenumber",
      (value) => isValidPhoneNumber(value, "GB"),
      "Please enter a valid phone number.",
    )
  }
}
