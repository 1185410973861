import { Controller } from "@hotwired/stimulus"

interface StimulusEvent extends Event {
  params: {
    optionsId: string
    radioId: string
  }
}

export default class OptionsFieldsetComponentController extends Controller {
  static targets = ["option", "radio"]

  optionTargets: Element[]
  radioTargets: Element[]

  select(event: StimulusEvent) {
    const selectedOption = document.getElementById(event.params.optionsId) as HTMLElement
    const selectedRadio = document.getElementById(event.params.radioId) as HTMLElement

    // Remove open attribute from all other options-radio elements
    this.removeAttributes(this.optionTargets, "open", selectedOption)

    // Remove checked attribute from all other options-radio input elements
    this.removeAttributes(this.radioTargets, "checked", selectedRadio)

    // Toggle open attribute for the clicked options-radio element
    if (selectedOption.hasAttribute("open")) {
      selectedOption.removeAttribute("open")
      selectedRadio.removeAttribute("checked")
    } else {
      selectedOption.setAttribute("open", "")
      selectedRadio.setAttribute("checked", "")
    }
  }

  removeAttributes = (elements: Element[], attribute: string, excludeElement: HTMLElement) => {
    elements.forEach((element: Element) => {
      if (element !== excludeElement && (element as HTMLElement).hasAttribute(attribute)) {
        ;(element as HTMLElement).removeAttribute(attribute)
      }
    })
  }
}
