import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["hidden"]

  static targets = ["modal"]

  show() {
    this.modalTarget.classList.remove(...this.hiddenClasses)
  }

  hide() {
    this.modalTarget.classList.add(...this.hiddenClasses)
  }
}
