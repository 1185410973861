import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

export default class extends Controller {
  static targets = ["container"]

  initialize() {
    this.containerTargets.forEach((element) => {
      element.innerHTML = ""
    })

    lottie.loadAnimation({
      container: document.getElementById("ML"),
      renderer: "svg",
      path: "/animations/ML.json",
      loop: true,
      autoplay: true,
    })

    lottie.loadAnimation({
      container: document.getElementById("ML-mob"),
      renderer: "svg",
      path: "/animations/ML-mob.json",
      loop: true,
      autoplay: true,
    })
  }
}
