import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content", "linkedTab"]

  initialize() {
    // activate first tab and content
    this.activeClass = this.element.dataset.activeclass || "active-tab"
    this.evaluateAnchor()
  }

  evaluateAnchor() {
    // check for anchor in document.location - activate valid tab or first tab
    let strippedUrl = document.location.toString().split("#")
    if (strippedUrl.length > 1 && this.element.querySelector(`[data-show=${strippedUrl[1]}]`)) {
      this.show(null, this.element.querySelector(`[data-show=${strippedUrl[1]}]`))
    } else {
      this.isHidden(this.tabTargets[0]) ? this.show(null, this.tabTargets[1]) : this.show(null, this.tabTargets[0])
    }
  }

  show(event, tab) {
    if (!tab) {
      tab = event.currentTarget
    }
    let contentToShow = tab.dataset.show
    this.tabTargets.forEach((elem) => elem.classList.toggle(this.activeClass, elem === tab))
    this.addLinkedElementTabs(tab)
    this.contentTargets.forEach((elem) => elem.classList.toggle("hidden", elem.id !== contentToShow))
  }

  isHidden(el) {
    let style = window.getComputedStyle(el)
    return style.display === "none"
  }

  addLinkedElementTabs(tab) {
    let linkedElements = tab.dataset.linked ? tab.dataset.linked.split(", ") : []
    linkedElements.push(tab.id)
    // Add Active Linked Class to Linked Elements
    this.linkedTabTargets.forEach((elem) => elem.classList.toggle(this.activeClass, linkedElements.includes(elem.id)))
  }
}
