import { Controller } from "@hotwired/stimulus"

// This controller is responsible for displaying modals and making sure all elements outside the modal are hidden.
// In an ideal world we would just have to add aria-modal="true" to the dialog element, however as of writing this
// controller that is not supported on all platforms https://www.digitala11y.com/aria-modalproperties.
export default class ModalContainerController extends Controller {
  static targets = ["container", "hide"]
  // TODO(web): Remove this value when every web app uses the web package
  static values = { fixScroll: Boolean }

  // These classes are used to fix the content in-place while a modal is displayed. overflow-auto also ensures a
  // scrollbar continue to be rendered if there was one without the modal.
  static HIDE_TARGET_CLASSES = ["fixed", "top-0", "bottom-0", "left-0", "right-0", "overflow-auto", "-z-10"]

  initialize() {
    this.mutationObserver = new MutationObserver(() => {
      if (this.containerTarget.childElementCount === 0) {
        this.hideTarget.removeAttribute("aria-hidden")
        this.containerTarget.classList.add("hidden")

        if (this.fixScrollValue) {
          // Get the scrollTop before modifying anything
          let scrollTop = this.hideTarget.scrollTop
          // Revert the hideTarget to a normal scrolling element
          this.hideTarget.classList.remove(...ModalContainerController.HIDE_TARGET_CLASSES)
          // Reset the document's scroll position to before the modal was opened
          document.documentElement.scrollTop = scrollTop
        }
      }
    })
    this.mutationObserver.observe(this.containerTarget, { childList: true })
  }

  disconnect() {
    this.mutationObserver.disconnect()
    delete this.mutationObserver
  }

  show({ target }) {
    this.containerTarget.appendChild(target.content.cloneNode(true))
    this.hideTarget.setAttribute("aria-hidden", "true")

    // Fix the hideTarget content in-place in the background so it cannot be scrolled
    if (this.fixScrollValue) {
      // Get the scrollTop before modifying anything
      let scrollTop = document.documentElement.scrollTop
      // Fix the background content in-place so that it does not scroll in the background
      this.hideTarget.classList.add(...ModalContainerController.HIDE_TARGET_CLASSES)
      // Set the scrollTop of the content to what the document was to keep it in the right place
      this.hideTarget.scrollTop = scrollTop
      document.documentElement.scrollTop = 0
    }
    this.containerTarget.classList.remove("hidden")
  }
}
