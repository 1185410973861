import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

const outsideClickListener = (event) => {
  if (!event.target.closest("[name=modal-container]")) {
    document.getElementsByName("modal").forEach((modal) => {
      modal.classList.add("hidden")
    })
  }
}

export default class extends Controller {
  static targets = ["table", "body", "search", "organisePath", "filter", "header", "columnsContainer", "checkbox"]
  static values = { params: Object }

  initialize() {
    document.addEventListener("click", outsideClickListener)
    this.params = new FormData()
    this.params.append("page", "1")
    this.headerTargets.forEach((elem, index) => {
      // add to toggle columns modal
      if (this.hasColumnsContainerTarget) {
        this.addColumnToggle(elem.innerText, index)
      }
    })
  }

  addColumnToggle(column, index) {
    let node = document.querySelector(".checkbox")
    let clone = node.cloneNode(true)
    clone.querySelector("span").innerText = column
    clone.querySelector("input").checked = true
    clone.querySelector("input").setAttribute("data-index", index)
    clone.classList.remove("hidden")
    this.columnsContainerTarget.appendChild(clone)
  }

  toggleColumn(event) {
    let index = Number(event.currentTarget.dataset.index)
    let cells = this.tableTarget.querySelectorAll(`td:nth-child(${index + 1}), th:nth-child(${index + 1})`)
    cells.forEach((cell) => {
      cell.classList.toggle("hidden")
    })
  }

  showAllColumns() {
    this.checkboxTargets.forEach((elem) => {
      elem.checked = true
    })
    this.tableTarget.querySelectorAll("td, th").forEach((cell) => {
      cell.classList.remove("hidden")
    })
  }

  filter() {
    this.updateFilterParams()
    this.fetchRows()
  }

  search(event) {
    event.preventDefault()
    this.filter()
  }

  sort(event) {
    this.updateSortParams(event.currentTarget)
    this.fetchRows()
  }

  updatePage(event) {
    event.preventDefault()
    this.params.set("page", event.currentTarget.dataset.page)
    this.fetchRows()
  }

  async fetchRows() {
    let tableBody = this.bodyTarget
    let response = await post(this.organisePathTarget.value, {
      body: this.params,
      responseKind: "html",
    })
    let html = await response.html
    if (response.ok) {
      tableBody.innerHTML = html
    }
  }

  updateSortParams(clickedHeader) {
    let newSortColumn = clickedHeader.dataset.sort
    let oldSortColumn = this.params.get("sort")
    this.params.set("sort", clickedHeader.dataset.sort)
    this.params.set("page", 1)
    if (newSortColumn === oldSortColumn) {
      // already sorted column so reverse direction
      let oldDirection = this.params.get("direction")
      let newDirection = oldDirection === "asc" ? "desc" : "asc"
      this.params.set("direction", newDirection)
      clickedHeader.classList.remove(oldDirection)
      clickedHeader.classList.add(newDirection)
    } else {
      // new sort column
      this.params.set("direction", "asc") // default
      this.headerTargets.forEach((header) => {
        if (header === clickedHeader) {
          header.classList.add("asc", "current")
          header.querySelector("[data-icon=arrow]").classList.remove("hidden")
        } else {
          header.classList.remove("current")
          header.querySelector("[data-icon=arrow]").classList.add("hidden")
        }
      })
    }
  }

  updateFilterParams() {
    this.params.set("page", "1")
    if (this.hasSearchTarget) {
      this.params.set("search", this.searchTarget.value)
    }
    // append all filter parameters (may be multiple filters)
    this.filterTargets.forEach((filter) => {
      let paramName = filter.querySelector("[name=param-name]").value
      let values = []
      filter.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
        if (checkbox.checked) {
          values.push(checkbox.value)
        }
      })
      this.setArrayParams(paramName, values)
      // add filter icon to headers if not all selected
      if (filter.querySelectorAll("input[type=checkbox]").length === values.length) {
        this.removeFunnel(paramName)
      } else {
        this.addFunnel(paramName)
      }
    })
  }

  setArrayParams(paramName, values) {
    this.params.delete(paramName + "[]")
    if (values.length > 0) {
      values.forEach((value) => this.params.append(paramName + "[]", value))
    } else {
      // param present but 'empty' for none selected
      this.params.append(paramName + "[]", "")
    }
  }

  clearFilter(event) {
    let container = event.currentTarget.closest("[name=modal-container]")
    container.querySelectorAll("input[type=checkbox]").forEach((elem) => {
      elem.checked = true
    })
    this.filter()
  }

  unselectAll(event) {
    let container = event.currentTarget.closest("[name=modal-container]")
    container.querySelectorAll("input[type=checkbox]").forEach((elem) => {
      elem.checked = false
    })
    this.filter()
  }

  toggleModal(event) {
    let container = event.currentTarget.closest("[name=modal-container]")
    let modalToShow = container.querySelector("[name=modal]")
    // hide all other modals
    document.getElementsByName("modal").forEach((modal) => {
      modal !== modalToShow ? modal.classList.add("hidden") : modal.classList.toggle("hidden")
    })
  }

  addFunnel(paramName) {
    this.headerTargets.forEach((header) => {
      if (header.querySelector("[data-icon=funnel]") && header.dataset.filter === paramName) {
        header.querySelector("[data-icon=funnel]").classList.remove("hidden")
      }
    })
  }

  removeFunnel(paramName) {
    this.headerTargets.forEach((header) => {
      if (header.dataset.filter === paramName && header.querySelector("[data-icon=funnel]")) {
        header.querySelector("[data-icon=funnel]").classList.add("hidden")
      }
    })
  }
}
