import { Controller } from "@hotwired/stimulus"

const outsideClickListener = (event) => {
  // hide dropdown on click out
  if (!event.target.closest("[name=button]")) {
    let options = document.getElementsByName("options")
    for (var i = 0; i < options.length; i++) {
      options[i].classList.add("hidden")
    }
  }
}

export default class extends Controller {
  static targets = ["options", "list", "current"]

  initialize() {
    document.addEventListener("click", outsideClickListener)
  }

  toggle() {
    // toggle dropdown visibility
    this.optionsTarget.classList.toggle("hidden")
    this.optionsTarget.scrollIntoView({ behavior: "smooth", block: "nearest" })
    this.scrollTo()
  }

  select(event) {
    let selected = this.element.getElementsByClassName("selected")[0]
    let clickedElem = event.currentTarget
    let input = this.element.getElementsByTagName("input")[0]
    // copy selected nodes from dropdown into visible input showing current selection
    this.currentTarget.innerHTML = ""
    for (let i = 0; i < clickedElem.childNodes.length; i++) {
      this.currentTarget.appendChild(clickedElem.childNodes[i].cloneNode(true))
    }

    // update dropdown list to keep track of which element is selected
    if (selected) {
      selected.classList.remove("selected")
    }

    clickedElem.classList.add("selected")

    // for input form
    input.setAttribute("value", clickedElem.getAttribute("value"))
  }

  scrollTo(event) {
    let search
    if (event) {
      // if the user has pressed a key - search for first letter of option text
      let key = event.key
      let options = this.element.querySelectorAll("[name=optionText]")
      for (var i = 0; i < options.length; i++) {
        if (options[i].innerText.startsWith(key.toUpperCase())) {
          search = options[i].closest("li")
          this.listTarget.scrollTop = search.offsetTop
          break
        }
      }
    } else {
      // make sure selected value is visible
      search = this.element.getElementsByClassName("selected")[0]
      if (search) {
        this.listTarget.scrollTop = search.offsetTop - 48
      }
    }
  }
}
