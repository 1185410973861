import { Controller } from "@hotwired/stimulus"
import ResizeObserver from "resize-observer-polyfill"
import scrollIntoView from "scroll-into-view-if-needed"

export default class ExpansionPanelComponentController extends Controller {
  static targets = ["wrapper", "content"]

  wrapperTarget: HTMLElement
  contentTarget: HTMLElement
  scroll: boolean

  static resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      // contentBoxSize is not yet supported by all browsers, fall back on contentRect if it is not an Array
      if (Array.isArray(entry.contentBoxSize)) {
        entry.target.parentElement.style.setProperty(
          "--expansion-panel--expanded-height",
          `${entry.contentBoxSize[0].blockSize}px`,
        )
      } else {
        entry.target.parentElement.style.setProperty(
          "--expansion-panel--expanded-height",
          `${entry.contentRect.height}px`,
        )
      }
    }
  })

  initialize() {
    ExpansionPanelComponentController.resizeObserver.observe(this.contentTarget)
  }

  startScrollingIfExpanding(event: TransitionEvent) {
    // Prevent duplicate event handling by only reacting to the height transition
    if (event.propertyName != "height") return

    if (window.getComputedStyle(this.wrapperTarget).getPropertyValue("--expansion-panel--expanded") === "true") {
      this.scroll = true
      this.scrollContentIntoView()
    }
  }

  scrollContentIntoView() {
    scrollIntoView(this.wrapperTarget, {
      behavior: "auto",
      block: "nearest",
      inline: "nearest",
      scrollMode: "if-needed",
    })
    if (this.scroll) {
      window.requestAnimationFrame(() => this.scrollContentIntoView())
    }
  }

  stopScrollingAndFireEvent(event: TransitionEvent) {
    // Prevent duplicate event handling by only reacting to the height transition
    if (event.propertyName !== "height") return
    this.scroll = false
    if (window.getComputedStyle(this.wrapperTarget).getPropertyValue("--expansion-panel--expanded") === "true") {
      this.dispatch("expanded")
    } else {
      this.dispatch("collapsed")
    }
  }
}
