import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["counter"]

  reduce() {
    let clickedElem = this.counterTarget
    let input = this.element.getElementsByTagName("input")[0]
    let counter = 0

    if (parseInt(clickedElem.innerHTML) > 0) {
      counter = parseInt(clickedElem.innerHTML) - 1
    }

    // for input form
    input.setAttribute("value", counter)
    clickedElem.innerHTML = counter
  }

  increase() {
    let clickedElem = this.counterTarget
    let input = this.element.getElementsByTagName("input")[0]
    const counter = parseInt(clickedElem.innerHTML) + 1

    // for input form
    input.setAttribute("value", counter)
    clickedElem.innerHTML = counter
  }
}
