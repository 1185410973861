import { Controller } from "@hotwired/stimulus"
import { Action, AppEmbed, AuthType, EmbedEvent, init } from "@thoughtspot/visual-embed-sdk"

export default class extends Controller {
  static targets = ["embed"]
  static values = {
    authEndpoint: String,
    baseUrl: String,
    loginFailedMessage: String,
    hostUrl: String,
    path: String,
    username: String,
    userLocale: String,
  }

  initialize() {
    init({
      authType: AuthType.AuthServer,
      autoLogin: true,
      authEndpoint: this.authEndpointValue,
      disableLoginRedirect: true,
      loginFailedMessage: this.loginFailedMessageValue, // loginFailedMessage can either be either text or HTML
      thoughtSpotHost: this.hostUrlValue,
      username: this.usernameValue,
    })

    const appEmbed = new AppEmbed(this.embedTarget, {
      frameParams: {
        width: "100%",
        height: "100%",
      },
      locale: this.userLocaleValue,
      hiddenActions: [
        Action.EditTML,
        Action.ExportTML,
        Action.ImportTML,
        Action.LiveboardInfo,
        Action.QueryDetailsButtons,
        Action.RequestAccess,
        Action.ShareViz,
        Action.UpdateTML,
      ],
      showPrimaryNavbar: false,
      path: this.pathValue,
    })

    appEmbed.on(EmbedEvent.RouteChange, (payload) => {
      // When user navigates to another page in ThoughtSpot update the ts_url param in the URL to match
      window.history.pushState({}, "", `${this.baseUrlValue}${payload.data.currentPath}`)
    })

    appEmbed.render()
  }
}
