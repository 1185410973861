// Javascript redirect hack as a workaround for turbo issue with following redirects to external URLS:
// https://github.com/hotwired/turbo/issues/401
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
  }

  initialize() {
    window.location.href = this.urlValue
  }
}
