import { Controller } from "@hotwired/stimulus"
import { createFocusTrap } from "focus-trap"

export default class extends Controller {
  static targets = ["dialog", "title"]
  static values = { clickOutsideDeactivates: { type: Boolean, default: true } }

  static nextId = 1

  initialize() {
    let id = `dialog-${this.constructor.nextId++}`
    this.titleTarget.id = `${id}-title`
    this.dialogTarget.setAttribute("aria-labelledby", this.titleTarget.id)

    this.focusTrap = createFocusTrap(this.dialogTarget, {
      clickOutsideDeactivates: this.clickOutsideDeactivatesValue,
      escapeDeactivates: true,
      onDeactivate: () => this.close(),
    })
  }

  connect() {
    this.focusTrap.activate()
  }

  disconnect() {
    this.focusTrap.deactivate()
  }

  close() {
    this.element.remove()
  }
}
