import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static classes = ["on", "off"]

  on() {
    this.elementTarget.classList.remove(...this.offClasses)
    this.elementTarget.classList.add(...this.onClasses)
  }

  off() {
    this.elementTarget.classList.remove(...this.onClasses)
    this.elementTarget.classList.add(...this.offClasses)
  }
}
