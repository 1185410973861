import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["id", "form"]

  save(event) {
    event.preventDefault()
    hideSuccess()
    let data = new FormData(this.formTarget)
    let controller = this
    Rails.ajax({
      type: "patch",
      url: `/customers/${this.idTarget.value}`,
      data,
      success: function (data) {
        // update header & sidebar with new initials
        controller.updateHeader(data)
        // display success message
        showSuccess("Changes saved successfully.")
      },
    })
  }

  updateHeader({ firstName, lastName }) {
    document.querySelectorAll("[name=initials]").forEach((elem) => {
      elem.innerHTML = `${firstName ? firstName[0].toUpperCase() : ""}${lastName ? lastName[0].toUpperCase() : ""}`
    })

    document.querySelectorAll("[name=full_name]").forEach((elem) => {
      elem.innerHTML = `${firstName} ${lastName}`
    })
  }
}
