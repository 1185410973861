import { Controller } from "@hotwired/stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {
  static targets = ["up", "down", "whole"]

  initialize() {
    document.getElementById("business-heading").innerHTML = ""

    var html =
      '<h2 class="up6 text-captionOnDark text-lg i8:text-xl md:text-2xl lg:text-2xl font-medium absolute" style="transform: translateY(-80px); opacity: 0;">Lower Regulatory Risk</span></h2>'
    html +=
      '<h2 class="up5 text-captionOnDark text-base i8:text-xl md:text-xl lg:text-2xl font-medium absolute" style="transform: translateY(-40px); opacity: 0.25;"> Improve Customer Relationships</span></h2>'
    html +=
      '<h2 class="up4 text-titleOnDark text-lg i8:text-xl md:text-2xl lg:text-2xl font-medium absolute" style="transform: translateY(0px); opacity: 1;"> Increase Recoveries</span></h2>'
    html +=
      '<h2 class="up3 text-captionOnDark text-lg i8:text-xl md:text-2xl lg:text-2xl font-medium absolute" style="transform: translateY(40px); opacity: 0.25;"> Minimise Cost to Collect</span></h2>'
    html +=
      '<h2 class="up2 text-captionOnDark text-lg i8:text-xl md:text-2xl lg:text-2xl font-medium absolute" style="transform: translateY(80px); opacity: 0;">Reduce Customer Churn</span></h2>'
    html +=
      '<h2 class="up1 text-captionOnDark text-lg i8:text-xl md:text-2xl lg:text-2xl font-medium absolute" style="transform: translateY(120px); opacity: 0;">Detect Vulnerability</span></h2>'

    document.getElementById("business-heading").innerHTML = html

    this.animate()
  }

  animate() {
    anime({
      targets: "#business-heading h2.up6",
      keyframes: [
        { translateY: 120, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 40, opacity: 0.25, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 0, opacity: 1, color: "#28ED91", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -40, opacity: 0.25, color: "#a6bbe4", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
      ],
      delay: 1500,
      loop: true,
    })
    anime({
      targets: "#business-heading h2.up5",
      keyframes: [
        { translateY: -80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 120, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 40, opacity: 0.25, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 0, opacity: 1, color: "#28ED91", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -40, opacity: 0.25, color: "#a6bbe4", duration: 500, delay: 1500, easing: "easeOutQuad" },
      ],
      delay: 1500,
      loop: true,
    })
    anime({
      targets: "#business-heading h2.up4",
      keyframes: [
        { translateY: -40, opacity: 0.25, color: "#a6bbe4", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 120, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 40, opacity: 0.25, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 0, opacity: 1, color: "#28ED91", duration: 500, delay: 1500, easing: "easeOutQuad" },
      ],
      delay: 1500,
      loop: true,
    })
    anime({
      targets: "#business-heading h2.up3",
      keyframes: [
        { translateY: 0, opacity: 1, color: "#28ED91", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -40, opacity: 0.25, color: "#a6bbe4", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 120, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 40, opacity: 0.25, duration: 500, delay: 1500, easing: "easeOutQuad" },
      ],
      delay: 1500,
      loop: true,
    })
    anime({
      targets: "#business-heading h2.up2",
      keyframes: [
        { translateY: 40, opacity: 0.25, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 0, opacity: 1, color: "#28ED91", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -40, opacity: 0.25, color: "#a6bbe4", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 120, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
      ],
      delay: 1500,
      loop: true,
    })
    anime({
      targets: "#business-heading h2.up1",
      keyframes: [
        { translateY: 80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 40, opacity: 0.25, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 0, opacity: 1, color: "#28ED91", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -40, opacity: 0.25, color: "#a6bbe4", duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: -80, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
        { translateY: 120, opacity: 0, duration: 500, delay: 1500, easing: "easeOutQuad" },
      ],
      delay: 1500,
      loop: true,
    })
  }
}
