import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "form", "frame"]
  static values = { url: String, clearInvalid: true }

  initialize() {
    this.load()
  }

  async load() {
    let url = new URL(this.urlValue, window.location)
    if (this.hasFormTarget) {
      if (!this.formTarget.checkValidity()) {
        if (this.clearInvalidValue) {
          this.clear()
        }
        return
      }
      for (const entry of new FormData(this.formTarget).entries()) {
        let [key, value] = entry
        if (key !== "authenticity_token" && typeof value === "string") {
          url.searchParams.append(key, value)
        }
      }
    }
    for (let fieldTarget of this.fieldTargets) {
      if (!fieldTarget.checkValidity()) {
        if (this.clearInvalidValue) {
          this.clear()
        }
        return
      }
      url.searchParams.append(fieldTarget.name, fieldTarget.value)
    }
    this.frameTarget.src = url.href
  }

  clear() {
    this.frameTarget.src = ""
    this.frameTarget.innerHTML = ""
  }
}
