import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.element.querySelectorAll(".field-float > input").forEach((input) => {
      if (!input.placeholder) {
        input.placeholder = " "
      }
    })
  }
}
