import { Controller } from "@hotwired/stimulus"

const FRONT_ELEMENT_ID = "front-chat-iframe"

export default class extends Controller {
  static values = { id: String }

  declare idValue: string

  disconnect() {
    frontPromise = null
  }

  async load() {
    const chatId: string = this.idValue

    await loadFront(chatId)
    FrontChat("init", { chatId, useDefaultLauncher: false })
    // There does not seem to be a good way to know when Front has finished loading. 500ms was not enough time.
    setTimeout(async () => {
      FrontChat("show")
      makeIframeAccessible()
    }, 1000)
  }
}

declare let FrontChat: any

let frontPromise: Promise<void>

function loadFront(chatId: string): Promise<void> {
  return (frontPromise ||= loadFrontScript(chatId))
}

let script: HTMLScriptElement

function loadFrontScript(chatId: string): Promise<void> {
  return new Promise((resolve) => {
    script?.remove()
    script = document.createElement("script")
    script.onload = () => resolve()
    script.defer = true
    script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js"
    script.dataset.chatId = chatId
    document.head.appendChild(script)
  })
}

function makeIframeAccessible() {
  const iframe = document.getElementById(FRONT_ELEMENT_ID)
  if (iframe) {
    iframe.setAttribute("aria-label", "Live chat")
  }
}
