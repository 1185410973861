import { Controller } from "@hotwired/stimulus"
import { createWidget } from "@typeform/embed"

export default class extends Controller {
  static values = { enableSandbox: Boolean, formId: String, title: String }

  initialize() {
    createWidget(this.formIdValue, {
      container: this.element,
      enableSandbox: this.enableSandboxValue,
      iframeProps: {
        title: this.titleValue,
        tabindex: "0", // iFrame elements participate in sequential keyboard navigation
      },
      tracking: { utm_source: location.href },
      inlineOnMobile: true,
    })
  }
}
