import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

export default class extends Controller {
  static targets = ["container"]

  initialize() {
    this.containerTargets.forEach((element) => {
      element.innerHTML = ""
    })

    lottie.loadAnimation({
      container: document.getElementById("hero"), // Required
      renderer: "svg", // Required
      path: "/animations/hero.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("wifi"), // Required
      renderer: "svg", // Required
      path: "/animations/wifi.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("clouds"), // Required
      renderer: "svg", // Required
      path: "/animations/clouds.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("phone"), // Required
      renderer: "svg", // Required
      path: "/animations/phone.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("post"), // Required
      renderer: "svg", // Required
      path: "/animations/post.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("chat"), // Required
      renderer: "svg", // Required
      path: "/animations/chat.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("rain"), // Required
      renderer: "svg", // Required
      path: "/animations/rain.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hand"), // Required
      renderer: "svg", // Required
      path: "/animations/hand.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })
  }
}
