import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  focus(event) {
    if (event.params.ignoreIfTargetIsElement && event.target === this.elementTarget) return
    if (document.activeElement !== this.elementTarget) this.elementTarget.focus()
  }
}
