import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    userPrefixId: String,
    debtPrefixId: String,
    debtorCategory: String,
    customerPrefixId: String,
    organisationPrefixId: String,
  }

  identifyUser() {
    // Identify the user on Segment by the user.prefix_id
    analytics.identify(this.userPrefixIdValue)
  }

  identifyDebt() {
    // Add the debt specific information to the traits of the user on Segment
    analytics.identify({
      customerPrefixId: this.customerPrefixIdValue,
      debtPrefixId: this.debtPrefixIdValue,
      debtorCategory: this.debtorCategoryValue,
      organisationPrefixId: this.organisationPrefixIdValue,
    })
  }

  track(event) {
    analytics.track(event.currentTarget.dataset.trigger)
  }
}
