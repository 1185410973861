import { Controller } from "@hotwired/stimulus"
import { createFocusTrap } from "focus-trap"

export default class extends Controller {
  static targets = ["modal", "close", "header"]
  static values = { clickOutsideDeactivates: { type: Boolean, default: true } }

  initialize() {
    this.focusTrap = createFocusTrap(this.headerTarget, {
      clickOutsideDeactivates: this.clickOutsideDeactivatesValue,
      escapeDeactivates: true,
      onDeactivate: () => this.hide(),
    })
  }

  disconnect() {
    this.focusTrap.deactivate()
  }

  show() {
    this.modalTarget.classList.remove("hidden")
    this.focusTrap.activate()
    // Stop scrolling while modal is open
    document.body.classList.add("overflow-hidden")
  }

  hide() {
    this.modalTarget.classList.add("hidden")
    // Allow scrolling again when modal is closed
    document.body.classList.remove("overflow-hidden")
  }
}
