import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]
  static values = { autoShow: Boolean, delay: { type: Number, default: 0 } }

  connect() {
    if (this.autoShowValue) {
      this.show()
    }
  }

  show() {
    setTimeout(() => {
      this.dispatch("show-modal", { target: this.templateTarget })
    }, this.delayValue)
  }
}
