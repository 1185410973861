import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropzone", "confirmation", "fileField", "fileNames"]

  setFileNames() {
    let file
    for (let i = 0; i < this.fileFieldTarget.files.length; i++) {
      file = document.createElement("div")
      file.innerText = this.fileFieldTarget.files[i].name
      this.fileNamesTarget.append(file)
    }
  }

  resetFileField() {
    this.fileNamesTarget.innerText = ""
    this.fileFieldTarget.value = ""
  }

  toggle() {
    if (this.fileFieldTarget.value === "") {
      this.showDropzone()
      this.dispatch("cleared")
    } else {
      this.showConfirmation()
      this.dispatch("selected")
    }
  }

  showDropzone() {
    this.dropzoneTarget.hidden = false
    this.confirmationTarget.hidden = true
  }

  showConfirmation() {
    this.dropzoneTarget.hidden = true
    this.confirmationTarget.hidden = false
  }
}
