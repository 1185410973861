import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["animElement"]

  checkPosition() {
    this.animElementTargets.forEach((elem) => {
      var positionFromTop = elem.getBoundingClientRect().top
      let windowHeight = window.innerHeight

      if (positionFromTop - windowHeight <= 0) {
        elem.classList.remove("translate-x-40")
        elem.classList.remove("-translate-x-40")
        elem.classList.remove("opacity-0")
      }
    })
  }
}
