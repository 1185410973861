import { Controller } from "@hotwired/stimulus"
import { Litepicker } from "litepicker"

export default class extends Controller {
  static targets = ["hidden", "displayed"]
  static values = { min: String, max: String, lang: String }

  initialize() {
    let controller = this
    return new Litepicker({
      element: this.displayedTarget,
      minDate: this.minValue,
      maxDate: this.maxValue,
      format: "DD/MM/YYYY",
      lang: this.langValue,
      setup: (picker) => {
        picker.on("selected", (date) => {
          controller.hiddenTarget.value = date.format("YYYY-MM-DD")
        })
      },
    })
  }
}
